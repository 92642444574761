@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
// Modal
.modal-wrapper {
  .modal.show {
    overflow: auto;
  }

  .modal-content {
    padding: 30px 47px;
    border-radius: $fp-card-radius;
  }

  .modal-content .modal-header {
    padding: 0;
    position: relative;
    border-bottom: none;

    .close {
      top: 0;
      right: -16px;
      opacity: 1;
      padding: 0 0.5rem;
      position: absolute;

      &:focus {
        outline: none;
      }
    }
  }

  .modal-title {
    @include fp-title;
  }

  .modal-body {
    font: {
      size: 14px;
      family: $font-family-secondary, $font-family-primary;
    }
    padding: 0;
    text-align: left;
    line-height: 21px;
    color: $fp-black;
  }

  .modal-footer {
    box-sizing: border-box;
    padding: 2rem 0 0 0;
    border-top: none;
    justify-content: center;
  }

  .modal.fade.show {
    display: block;
    .modal-image {
      max-width: 75%;
    }
    @media (max-width: 767px) {
      .modal-image {
        max-width: 100%;
      }
    }
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-lg {
    height: 600px;
    width: 500px;
  }
}
