@import '../../styles/_variables.scss';

.fp-navbar {
  .navbar {
    padding: 15px 0.8rem;
    background-color: var(--primary-color);
    background-image: none;
    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      .navbar-img {
        height: 50px;
      }
    }

    .logo-img {
      margin-right: 0.8rem;
      width: inherit;
      height: inherit;
      &.hito {
        width: 40%;
      }

      &.vida {
        width: 22%;
      }
    }

    .logo-text {
      font-size: 20px;
      font-weight: 500;
      color: $fp-white;
      font-family: $font-family-secondary, sans-serif;
      text-transform: uppercase;
    }

    .nav-item {
      font-family: $font-family-secondary, sans-serif;
    }

    .nav-link {
      padding-right: 0px;
    }

    .dropdown-menu {
      font-size: small;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      background-color: var(--primary-color);
    }

    box-shadow: 0 2px 40px 0 rgba(65, 70, 76, 0.07);

    .notification-icon {
      cursor: pointer;
    }
  }

  .notification-window {
    position: absolute;
    max-height: 30rem;
    width: 25rem;
    background-color: white;
    margin-top: 0.7rem;
    border-radius: 4px;
    margin-left: -15rem;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.09);
  }
}
