@import './_variables.scss';

// Navbar mixin
@mixin footprints-navbar-mixin(
  $gradient-deg: 97.03deg,
  $gradient-color-one: $fp-dark-purple,
  $gradient-color-two: $fp-dark-purple
) {
  background-image: linear-gradient(
    $gradient-deg,
    $gradient-color-one,
    $gradient-color-two
  );
  box-shadow: $fp-navbar-shadow;
  .nav-link {
    color: $fp-white;
  }
}

// Navbar generate classes
@mixin navbar-classes {
  @for $i from 1 through length($fp-gradient-list) {
    $element: nth($fp-gradient-list, $i);
    $color-one: nth($element, 1);
    $color-two: nth($element, 2);
    .nav-#{$i} {
      @include footprints-navbar-mixin(
        $gradient-color-one: $color-one,
        $gradient-color-two: $color-two
      );
    }
  }
}

@mixin fp-title {
  margin-bottom: 3rem;
  text-align: left;
  font-family: Barlow, sans-serif;
}

@mixin fp-data-card-title {
  font-weight: 600;
  font-size: 14px;
  font-family: Barlow, sans-serif;
  // margin-bottom: 0;
}

@mixin fp-history-dots {
  @for $i from 1 through length($fp-tracking-logs-color) {
    $j: $i - 1;
    .circle[log='#{$j}'] {
      background: nth($fp-tracking-logs-color, $i);
    }
  }
}

@mixin fp-history-title {
  @for $i from 1 through length($fp-tracking-logs-color) {
    $j: $i - 1;
    .item-data-title[log='#{$j}'] {
      color: nth($fp-tracking-logs-color, $i);
    }
  }
}

@mixin fp-history-title-background {
  @for $i from 1 through length($fp-tracking-logs-color) {
    $j: $i - 1;
    $color: nth($fp-tracking-logs-color, $i);
    .item-data[log='#{$j}'] {
      background-color: rgba(red($color), green($color), blue($color), 0.08);
      border-radius: 0.8rem;
    }
  }
}

@mixin header-text-mixin {
  font-size: 1rem;
  font-weight: 500;
  text-transform: none;
  color: $fp-text-black;
  font-family: $font-family-secondary, sans-serif;
}

@mixin notification-title-background {
  @for $i from 1 through length($fp-tracking-logs-color) {
    $j: $i - 1;
    $color: nth($fp-tracking-logs-color, $i);
    .notification-header[log='#{$j}'] {
      background-color: rgba(red($color), green($color), blue($color), 0.08);
      border-radius: 0.8rem;
    }
  }
}

@mixin notification-title-text {
  @for $i from 1 through length($fp-tracking-logs-color) {
    $j: $i - 1;
    .notification-header-text[log='#{$j}'] {
      color: nth($fp-tracking-logs-color, $i);
    }
  }
}
