// Footprints colors
$fp-grey: #c3c3c3;
$fp-sidebar-hover: #f2f2f2;
$fp-sidebar-active: #ececec;
$fp-gray-border: #e5e5e5;
$fp-sidebar-text: #303239;
$fp-sidebar-header: #333;
$fp-sidebar-background: #fafafa;
$fp-white: #fff;
$fp-purple: #7e3fdf;
$fp-violet: #ac5bf6;
$fp-purple-two: #603584;
$fp-light-blue: #2086ff;
$fp-light-blue-two: #56d3ff;
$fp-blue: #467ac7;
$fp-dark-purple: #402b63;
$fp-red: #c34a4d;
$fp-red-two: #f05f57;
$fp-dark-purple-two: #360940;
$fp-orange: #f8845a;
$fp-light-orange: #ffb27a;
$fp-pink: #fe4ba6;
$fp-green: #35adb4;
$fp-light-green: #82dd94;
$fp-pink-two: #f761a1;
$fp-violet-two: #8c1bab;
$fp-yellow: #e7da81;
$fp-pink-three: #f6416c;
$fp-new-blue: #1854cf;
$fp-new-light-blue: #00cfe2;
$fp-text-black: #303239;
$fp-btn-light: #acb1be;
$fp-text-grey: #898989;
$fp-text-grey-two: #6e727d;
$fp-list-border-gray: #c0c8d9;
$fp-list-selected: #f9f9fc;
$fp-primary-gradient: linear-gradient(
  117deg,
  $fp-new-blue,
  $fp-new-light-blue 100%
);
$fp-greyed-out-blue: #7d9ac5;
$fp-background: #fafcff;
$fp-black: #000;
$fp-table-border-color: #edeef4;
$fp-grey-medium: #495057;
// Footprints fonts
$font-family-primary: 'Open Sans';
$font-family-secondary: 'Barlow';

// Footprints elements
$fp-card-radius: 8px;
$fp-radius: 4px;

// Miscellaneous
$fp-shadow-dark: rgba(0, 0, 0, 0.1);
$fp-navbar-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
$fp-gradient-list: (
  ($fp-blue, $fp-blue),
  ($fp-purple, $fp-light-blue),
  ($fp-red, $fp-orange),
  ($fp-light-orange, $fp-pink),
  ($fp-light-blue-two, $fp-blue),
  ($fp-violet, $fp-purple-two),
  ($fp-green, $fp-light-green),
  ($fp-purple, $fp-light-blue),
  ($fp-pink-two, $fp-violet-two),
  ($fp-yellow, $fp-pink-three),
  ($fp-red-two, $fp-dark-purple-two)
);
$content-shadow: 0 2px 8px 0 rgba(182, 190, 205, 0.16);

// Bootstrap Theme Colors
$theme-colors: (
  'primary': $fp-light-blue
);

$font-family-sans-serif: $font-family-primary;
$fp-data-card-hover: #f5f5f5;
$fp-data-card-text: #777;

$fp-background-image: linear-gradient(to bottom, #ffffff, #f5f7ff 73%, #f5f7ff);

$fp-tracking-logs-color: #00c853, #ff9800, #2196f3, #ec407a, #fdd835, #673ab7;

$web-app-theme-colors: (
  'color-1': $fp-light-blue,
  'color-2': #f75849,
  'color-3': #edba13,
  'color-4': #e67218,
  'color-5': #7eb704,
  'color-6': #0fba49,
  'color-7': #405ccd,
  'color-8': #7648c6,
  'color-9': #ba44c6,
  'color-10': #e65685
);

:root {
  --primary-color: #{$fp-light-blue};
  --primary-text-color: #{$fp-white};
  --content-header: linear-gradient(
    to right,
    rgba(var(--primary-color-rgb), 0.2),
    rgba(var(--primary-color-rgb), 0.1)
  );
}
