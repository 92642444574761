@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500,600');
@import '_variables.scss';
@import '_mixins.scss';
@import '~bootstrap/scss/bootstrap.scss';

// _____________ Navbar ________________
@include navbar-classes;

// Override Bootstrap Style
body {
  font-size: 14px;
  font-weight: 300;
  background-color: #eaedf2;
  background-size: 100%;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  font-family: $font-family-secondary;
  background-image: url('../images/background.svg');

  &::-webkit-scrollbar {
    display: none;
  }
}

.footprints-body {
  margin-top: 6rem;
}

// Card Styles
.card {
  &.fp-card {
    margin: auto;
    border: none;
    max-width: 500px;
    border-radius: $fp-card-radius;
  }

  .btn-light {
    border: none;
    text-decoration: none;
  }

  .fp-card-body {
    padding: 2.5rem 3.5rem;
  }

  .fp-card-title {
    @include fp-title;
  }

  .fp-details-card-title {
    font-weight: 600;
    font-size: 1.2rem;
    font-family: $font-family-secondary;
    margin-bottom: 0.75rem;
  }
}

// Input and Input Group Styles
.input-group {
  margin-bottom: 25px;

  input {
    border: none;
    border-radius: 0;
    background: transparent;
    border-bottom: 1px solid $fp-grey;

    &:active,
    &:focus {
      border-bottom: 1px solid $fp-light-blue;
    }
  }
  .form-control:focus {
    box-shadow: none;
  }
}

.input-group-text {
  border: none;
  border-radius: 0;
  background: transparent;
}

// Input Placeholder Style
input#email::-webkit-input-placeholder,
input#password::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c0c0c0;
}
input#email::-moz-placeholder,
input#password::-moz-placeholder {
  /* Firefox 19+ */
  color: #c0c0c0;
}
input#email:-ms-input-placeholder,
input#password:-ms-input-placeholder {
  /* IE 10+ */
  color: #c0c0c0;
}
input#email:-moz-placeholder,
input#password:-moz-placeholder {
  /* Firefox 18- */
  color: #c0c0c0;
}

// Button
.btn {
  line-height: 24px;
  border-radius: $fp-radius;
  padding: 3px 16px;
  text-transform: capitalize;
  letter-spacing: 1px;

  &.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);

    &:focus {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      box-shadow: 0 0 0 0.1rem var(--primary-color);
    }

    &:not(:disabled):not(.disabled):active,
    &.btn-primary:not(:disabled):not(.disabled).active,
    &.show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  &.onboarding-btn {
    margin-top: 3rem;
  }

  font: {
    weight: 500;
    size: 15px;
    family: $font-family-secondary, sans-serif;
  }

  &.btn-light {
    color: $fp-btn-light;
  }
}

//Delete Button
.delete-btn {
  background-color: transparent;
  border-color: transparent;
  padding: 0%;
  font-size: 1.2rem;
  color: red;
}

.heading-title {
  font-weight: 500;
  font-size: 20px;
}
.sub-heading-title {
  font-size: 14px;
}

// Plus button for any adds
.add-icon {
  padding-right: 0.2rem;
  color: var(--primary-color);
}

// Page content appearance excluding the page header
.content-body {
  background-color: $fp-white;

  // 110px: Height of the navbar with extra spacing
  // 4rem: Height of the page header with extra spacing
  height: calc(100vh - 4rem - 110px);

  border-radius: 0 0 $fp-radius $fp-radius;
  padding: 1.4rem 2rem;
  box-sizing: border-box;
  overflow: auto;
}

.content-body-no-header {
  background-color: $fp-white;

  // 110px: Height of the navbar with extra spacing
  height: calc(100vh - 110px);

  border-radius: 0 0 $fp-radius $fp-radius;
  box-sizing: border-box;
  overflow: auto;
}

// The page header styling
.header-text {
  @include header-text-mixin;
  box-sizing: border-box;
  height: 100%;
  display: inline-block;
  padding: 1.4rem 2rem;
}

.form-head {
  @include header-text-mixin;
  margin-bottom: 1rem;
}
