@import '../../styles/_variables.scss';

$radius: 4px;
$sidebar-width: 240px;
$sidebar-font-size: 14px;
$sidebar-collapsed-width: 60px;
$sidebar-transition-time: 0.3s;
$icon-margin-right: 15px;

.layout {
  margin-top: 15px;

  // Generic styling
  .sidebar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    z-index: 3;
    overflow-y: scroll;
    position: fixed;
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
    border-radius: $radius;
    width: $sidebar-width;
    box-sizing: border-box;
    height: calc(100vh - 110px);
    background-color: $fp-white;
    font-family: $font-family-secondary;
    transition: all $sidebar-transition-time;
    box-shadow: 0 2px 8px 0 rgba(182, 190, 205, 0.16);

    // Generic styling for the items
    ul .nav-link {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
      border-radius: 4px;
      text-decoration: none;
      color: $fp-sidebar-text;

      &:hover {
        cursor: pointer;
        background-color: $fp-sidebar-hover;
      }

      &.version:hover {
        cursor: default;
        background-color: inherit;
      }

      img {
        height: 20px;
        margin-right: $icon-margin-right;
      }
    }

    .top-items {
      width: 100%;

      ul .nav-link.selected {
        color: $fp-white;
        background-color: var(--primary-color);
      }

      ul .nav-link.selected img.inactive {
        display: none;
      }
      ul .nav-link.selected img.active {
        display: inline;
      }

      ul .nav-link img.inactive {
        display: inline;
      }
      ul .nav-link img.active {
        display: none;
      }
    }

    .bottom-items {
      width: 100%;
      background-color: #fcfaf9;
      border-top: 1px solid #eef0f4;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;

      ul {
        margin-bottom: 0;
      }

      ul .nav-link {
        font-weight: normal;
      }

      ul .nav-link:first-child {
        margin-top: 15px;
      }

      ul .nav-link.selected {
        color: $fp-black;
        background-color: #eceff1;
      }
    }

    .middle-items {
      width: 100%;
      ul .nav-link.selected {
        color: $fp-white;
        background-color: var(--primary-color);
      }

      ul .nav-link.selected img.inactive {
        display: none;
      }
      ul .nav-link.selected img.active {
        display: inline;
      }

      ul .nav-link img.inactive {
        display: inline;
      }
      ul .nav-link img.active {
        display: none;
      }
    }
  }

  .header {
    display: block;
    padding: 0.8rem;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: $fp-sidebar-header;
    font-family: $font-family-secondary;
  }

  .header-small-width {
    display: none;

    #arrowExpand {
      display: none;
    }
  }

  // Content
  .content {
    transition: all $sidebar-transition-time;
    margin-left: calc(#{$sidebar-width} + 15px);
    height: calc(100vh - 110px);
    border-radius: $fp-radius;

    &.wide {
      margin-left: 0;
    }

    .icon-btn {
      color: var(--primary-color);
      padding: 2px 8px;
      border-radius: 4px;
      background: $fp-white;
      border: 1px solid var(--primary-color);
    }

    .breadcrumbs-nav {
      padding: 0;
      margin-bottom: 0;
    }
  }

  .sidebar-dismiss {
    right: 12px;
    display: none;
    color: $fp-text-black;
    margin-top: 12px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: right;
    cursor: pointer;
    transition: all $sidebar-transition-time;

    &:active {
      color: $fp-white;
      background-color: var(--primary-color);
    }
  }

  .sidebar-collapse-btn {
    display: none;
  }

  @media (max-width: 767px) {
    .sidebar {
      margin-left: calc(-#{$sidebar-width} - 15px);

      .sidebar-dismiss {
        left: -40px;
        display: none;
      }

      &.expanded {
        margin-left: 0;

        .sidebar-dismiss {
          display: block;
          left: calc(#{$sidebar-width} - 40px);
        }
      }
    }

    .content {
      margin-left: 0;

      .sidebar-collapse-btn {
        display: block;
      }

      .breadcrumbs-nav {
        padding: 0.4rem 0 0.2rem 0;
        border-bottom: 1px solid #f1f1f1;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .sidebar {
      padding-top: 0;
      width: $sidebar-collapsed-width;

      .header {
        display: none;
      }

      .header-small-width {
        margin: 10px;
        margin-bottom: 20px;
        display: block;
        cursor: pointer;
        color: var(--primary-color);

        .open-sidenav {
          width: 100%;
        }

        #arrowExpand {
          display: block;
        }
      }

      ul .nav-link {
        text-align: center;
        padding: 5px;
        margin: 10px;

        img {
          margin: 0;
        }
      }

      ul .nav-link .sidenav-item-text {
        display: none;
      }

      ul .nav-link .sidenav-bottom-item-text {
        display: none;
      }

      &.expanded {
        width: $sidebar-width;

        .header {
          display: block;
        }

        .sidebar-dismiss {
          display: block;
        }

        .header-small-width {
          display: none;
        }

        ul a .sidenav-item-text {
          display: inline;
        }

        ul .nav-link .sidenav-bottom-item-text {
          display: inline;
        }

        ul .nav-link {
          margin-left: 20px;
          margin-right: 20px;
          border-radius: 4px;
          text-align: left;
          padding: 0.5rem 1rem;

          img {
            margin-right: $icon-margin-right;
          }
        }
      }
    }

    .content {
      margin-left: calc(#{$sidebar-collapsed-width} + 15px);
    }
  }

  .navbar-ruler {
    margin: unset;
  }

  .middle-items {
    border-top: 1px solid #eef0f4;

    li:first-child {
      margin-top: 0.5rem;
    }
  }
}
