@import '../../styles/_variables.scss';
@import '../../styles/mixins';

.notification-box {
  .notification-box-header {
    padding: 0.8rem;
    font-size: 16px;
    font-weight: 500;

    .mark-read-button {
      font-size: 14px;
      font-weight: 300;
      border: none;
      text-decoration-line: underline;
      color: #2086ff;
    }
  }

  .notification-box-body {
    border-top: solid 1px rgba(0, 0, 0, 0.11);
    overflow: auto;
    max-height: 27rem;

    .no-notification {
      padding: 1rem;
    }

    .each-notification {
      cursor: pointer;
      padding: 1rem 1rem 0rem 1rem;
      font-size: 13px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.11);

      &.each-notification:hover {
        background-color: rgba($fp-grey, 0.1);
      }

      &.unseen-notification {
        background-color: rgba($fp-grey, 0.2);
      }

      .notification-header {
        padding: 2px 6px;

        .circle {
          transform: translateY(15%);
          border-radius: 50%;
          width: 0.7rem;
          height: 0.7rem;
          margin-top: 3px;
          opacity: 0.7;
        }
        @include fp-history-dots;

        .notification-header-text {
          padding: 0px 12px;
          font-weight: 500;
        }

        .date-time {
          font-size: 12px;
          color: #303239;
          margin-right: 1rem;
        }
      }
      @include notification-title-text;
      @include notification-title-background;

      .notification-body {
        padding: 10px 10px;

        .asset-name {
          font-size: 14px;
          font-weight: 500;
        }

        .asset-id {
          font-size: 10px;
        }

        .view-button {
          border: none;
          color: var(--primary-color);
          font-weight: 500;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: $fp-grey;
  }
}
