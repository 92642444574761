@import '../../styles/_variables.scss';

.warning-message {
  top: 50%;
  left: 50%;
  overflow: auto;
  max-width: 100%;
  font-size: 30px;
  font-weight: 600;
  max-height: 100%;
  line-height: 42px;
  position: absolute;
  transform: translate(-50%, -50%);
  font-family: $font-family-secondary;
}
